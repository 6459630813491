import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ThemeModeSwitch } from '@components/layout/TopMenu/ThemeModeSwitch';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import { FC } from 'react';
import { ListItemText, PaletteMode } from '@mui/material';

interface AccountMenuProps {
  themeMode: PaletteMode;
  toggleMode: () => void;
  handleProfile: VoidFunction;
  handleLogout: VoidFunction;
  handleChangePassword: VoidFunction;
  onClose: VoidFunction;
  anchorEl: null | HTMLElement;
  isAccountMenuOpen: boolean;
}

const AccountMenu: FC<AccountMenuProps> = ({
  themeMode,
  toggleMode,
  handleProfile,
  handleLogout,
  onClose,
  anchorEl,
  isAccountMenuOpen,
  handleChangePassword,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isAccountMenuOpen}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 16,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem>
        <ListItemIcon>
          <DarkModeIcon fontSize="small" />
        </ListItemIcon>
        <ThemeModeSwitch mode={themeMode} toggleMode={toggleMode} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleProfile}>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Profile details</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleChangePassword}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Change password</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;
