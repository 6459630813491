import React, { FC } from 'react';
import { PaletteMode } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

type ThemeModeSwitchProps = {
  mode: PaletteMode;
  toggleMode: () => void;
};

export const darkModeSwitchTestId = 'dark-mode-switch-id';

export const ThemeModeSwitch: FC<ThemeModeSwitchProps> = ({ mode, toggleMode }) => {
  const isDarkMode = mode === 'dark';

  return (
    <FormControlLabel
      value="start"
      control={
        <Switch data-testid={darkModeSwitchTestId} checked={isDarkMode} onChange={toggleMode} />
      }
      label="Dark mode"
      labelPlacement="start"
      sx={{ ml: 0 }}
    />
  );
};
