import { Token } from '@typings/token.types';
import { subscribeCookieChange, unsubscribeCookieChange } from '@utils/cookies';
import { FC, ReactNode, useEffect, useReducer } from 'react';
import AuthContext from './context';
import authReducer from './reducer';
import { clearAccessTokens, setRefreshedTokens } from './reducer/actions';

interface Props {
  children: ReactNode;
  accessToken: string;
  refreshToken: string;
  passwordChangeToken: string;
}

const AuthProvider: FC<Props> = ({ children, accessToken, refreshToken, passwordChangeToken }) => {
  let userName = '';

  if (accessToken) {
    userName = JSON.parse(atob(accessToken.split('.')[1])).uname;
  }

  const [state, dispatch] = useReducer(authReducer, { accessToken, refreshToken, userName, passwordChangeToken });

  const onCookieChange = ({ detail }: CustomEventInit<Token>) => {
    if (!detail) {
      dispatch(clearAccessTokens());
      return;
    }
    if (detail.accessToken && detail.refreshToken) {
      dispatch(setRefreshedTokens(detail));
    }
  };

  useEffect(() => {
    subscribeCookieChange(onCookieChange);

    return () => unsubscribeCookieChange(onCookieChange);
  }, []);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
