import Typography from '@mui/material/Typography';
import { FC } from 'react';

export interface SectionHeaderProps {
  title: string;
}

const SectionHeader: FC<SectionHeaderProps> = ({ title }) => {
  return (
    <Typography
      variant="h5"
      gutterBottom
      sx={{
        mt: 2.5,
        mb: 2.5,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
      }}
    >
      {title}
    </Typography>
  );
};

export default SectionHeader;
