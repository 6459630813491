import React from 'react';
import { Link } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export const HeaderLink = styled(Link)<LinkProps>(({ theme }) => ({
  textDecoration: 'none',
  color: theme.header.text,
  fontWeight: 700,
  fontSize: '.875rem',
  cursor: 'pointer',
}));

const Links = () => (
  <>
    <HeaderLink to="https://wst.tv/" target="_blank" rel="noopener noreferrer">
      WST.TV
    </HeaderLink>
    <HeaderLink to='/'>TOURNAMENTS</HeaderLink>
    <HeaderLink to={APP_PATHS.SPONSORS.ROOT}>SPONSORS</HeaderLink>
    <HeaderLink to={APP_PATHS.CONTACT.ROOT}>CONTACT</HeaderLink>
  </>
);

export default Links;
