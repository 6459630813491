import { useTheme } from '@mui/material/styles';
import React, { FC, MouseEventHandler } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useMobile from '@hooks/useMobile';
import { Avatar } from '@mui/material';

interface AvatarProps {
  handleAvatarClick: MouseEventHandler<HTMLButtonElement> | undefined;
  isAccountMenuOpen: boolean;
  username: string;
}

const AvatarLink: FC<AvatarProps> = ({ handleAvatarClick, isAccountMenuOpen, username }) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const AvatarComponent = (
    <Avatar sx={{ backgroundColor: theme.palette.text.secondary }}>
      {username ? username[0].toUpperCase() : ''}
    </Avatar>
  );

  if (isMobile) {
    return AvatarComponent;
  }

  return (
    <Tooltip title="Account settings">
      <IconButton
        onClick={handleAvatarClick}
        size="small"
        sx={{ ml: 1 }}
        aria-controls={isAccountMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isAccountMenuOpen ? 'true' : undefined}
      >
        {AvatarComponent}
      </IconButton>
    </Tooltip>
  );
};

export default AvatarLink;
