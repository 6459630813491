interface ClearAction {
  type: AUTH_ACTION.CLEAR_DATA;
  payload: undefined;
}

interface SetLoginDataAction {
  type: AUTH_ACTION.SET_LOGIN_DATA;
  payload: {
    userName: string;
    accessToken: string;
    refreshToken: string;
    passwordChangeToken: string;
  };
}

interface SetRefreshedTokensAction {
  type: AUTH_ACTION.SET_REFRESHED_TOKENS;
  payload: {
    accessToken: string;
    refreshToken: string;
  };
}

export enum AUTH_ACTION {
  SET_LOGIN_DATA = 'setLoginData',
  SET_REFRESHED_TOKENS = 'setRefreshedTokens',
  CLEAR_DATA = 'clearData',
}

export type AuthAction = ClearAction | SetLoginDataAction | SetRefreshedTokensAction;

export const clearAccessTokens = (): ClearAction => ({
  type: AUTH_ACTION.CLEAR_DATA,
  payload: undefined,
});

export const setRefreshedTokens = (
  payload: SetRefreshedTokensAction['payload']
): SetRefreshedTokensAction => ({
  type: AUTH_ACTION.SET_REFRESHED_TOKENS,
  payload,
});

export const setLoginData = (payload: SetLoginDataAction['payload']): SetLoginDataAction => ({
  type: AUTH_ACTION.SET_LOGIN_DATA,
  payload,
});
