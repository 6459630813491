import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { request } from '@services/request';
import { ChangePasswordType, ForgottenPasswordType, ResetPasswordType, UserType, ValidatePasswordResetTokenType } from '@typings/account.types';
import { Token, TokenResponse } from '@typings/token.types';
import { RevokeToken, ResultType } from '@typings/common';

export enum ClientType {
  CMS = 1,
  PLAYER_PORTAL = 2,
}

const login = async (username: string, password: string): Promise<ResultType<TokenResponse>> => {
  return await request(`${API_PATH.accounts}/login`, ApiMethod.POST, {
    username,
    password,
    clientType: ClientType.PLAYER_PORTAL,
  });
};

const refreshToken = async (token: Token): Promise<ResultType<TokenResponse>> => {
  const { accessToken: jwtToken, refreshToken } = token;
  return await request(`${API_PATH.accounts}/refresh-token`, ApiMethod.POST, {
    jwtToken,
    refreshToken,
    clientType: ClientType.PLAYER_PORTAL,
  });
};

const changePassword = async (password: Partial<ChangePasswordType>) =>
  request(`${API_PATH.accounts}/change-password`, ApiMethod.PUT, password);

const changePasswordUsingToken = async (password: Partial<ChangePasswordType>, token: string) =>
  request(`${API_PATH.accounts}/change-password`, ApiMethod.PUT, password, token);

const revokeToken = async (token: Partial<RevokeToken>) =>
  request(`${API_PATH.accounts}/revoke-token`, ApiMethod.POST, token);

const getCurrentAccount = async (): Promise<UserType> =>
  request(`${API_PATH.accounts}`, ApiMethod.GET);

const forgotPassword = async (usernameOrEmail: Partial<ForgottenPasswordType>) =>
  request(`${API_PATH.accounts}/forgot-password`, ApiMethod.POST, usernameOrEmail);

const resetPassword = (password: Partial<ResetPasswordType>) => 
  request(`${API_PATH.accounts}/reset-password`, ApiMethod.PUT, password);

const validatePasswordResetToken = (token: Partial<ValidatePasswordResetTokenType>) => 
  request(`${API_PATH.accounts}/validate-password-reset-token`, ApiMethod.POST, token);

const getProfile = async (): Promise<UserType> => {
  return await request(
    `${API_PATH.accounts}?withNationality=true&withPlaceOfBirth=true&withDenotation=true&withRoles=true&withContacts=true&withNames=true&withAddresses=true&withProfiles=true&withExtendedData=true`,
    ApiMethod.GET
  );
};

export const accountsApi = {
  login,
  revokeToken,
  refreshToken,
  changePassword,
  changePasswordUsingToken,
  getCurrentAccount,
  getProfile,
  forgotPassword,
  resetPassword,
  validatePasswordResetToken,
};
