import { Outlet } from 'react-router';

import { Box } from '@mui/material';
import Container from '@mui/material/Container';

import CookieNotice from '../CookieNotice/CookieNotice';

const AuthLayout = () => (
  <Container
    maxWidth="lg"
    sx={{
      pl: 0,
      pr: 0,
    }}
  >
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Outlet />
    </Box>
    <CookieNotice />
  </Container>
);

export default AuthLayout;
