import { setTokenCookies, setPasswordChangeTokenCookie } from '@utils/cookies';
import { AuthAction, AUTH_ACTION } from './actions';
import { Token } from '@typings/token.types';

export type AuthState = Token & {
  userName: string;
  passwordChangeToken: string;
};

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  userName: '',
  passwordChangeToken: '',
};

const authReducer = (state = initialState, { type, payload }: AuthAction): AuthState => {
  switch (type) {
    case AUTH_ACTION.SET_LOGIN_DATA:
      setTokenCookies(payload.accessToken, payload.refreshToken);
      setPasswordChangeTokenCookie(payload.passwordChangeToken);

      return {
        ...state,
        ...payload,
      };
    case AUTH_ACTION.SET_REFRESHED_TOKENS:
      return {
        ...state,
        ...payload,
      };
    case AUTH_ACTION.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
