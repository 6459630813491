import { Token } from '@typings/token.types';
import Cookies from 'js-cookie';
import { PaletteMode } from '@mui/material';

const COOKIES_CHANGE = 'cookieChange';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const PASSWORD_CHANGE_TOKEN_KEY = 'passwordChangeToken';
const THEME_KEY = 'theme';

export const clearAuthCookies = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
  Cookies.remove(PASSWORD_CHANGE_TOKEN_KEY);

  publishCookieChange();
};

export const subscribeCookieChange = (listener: EventListenerOrEventListenerObject) => {
  document.addEventListener(COOKIES_CHANGE, listener);
};

export const unsubscribeCookieChange = (listener: EventListenerOrEventListenerObject) => {
  document.removeEventListener(COOKIES_CHANGE, listener);
};

export const publishCookieChange = (detail?: Token) => {
  const event = new CustomEvent(COOKIES_CHANGE, { detail });
  document.dispatchEvent(event);
};

export const setTokenCookies = (accessToken: string, refreshToken: string) => {
  Cookies.set(ACCESS_TOKEN_KEY, accessToken);
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken);
};

export const getTokenCookies = (): Token => {
  return {
    accessToken: Cookies.get(ACCESS_TOKEN_KEY) || '',
    refreshToken: Cookies.get(REFRESH_TOKEN_KEY) || '',
  };
};

export const setPasswordChangeTokenCookie = (token: string) => {
  Cookies.set(PASSWORD_CHANGE_TOKEN_KEY, token);
};

export const getPasswordChangeTokenCookie = (): string => {
  return Cookies.get(PASSWORD_CHANGE_TOKEN_KEY) || '';
};

export const setThemeMode = (value: PaletteMode) => {
  Cookies.set(THEME_KEY, value);
};

export const useDarkModeFromCookie = () => {
  return Cookies.get(THEME_KEY) === 'dark';
};

export const removeRefreshToken = () => {
  Cookies.remove(REFRESH_TOKEN_KEY);
};
