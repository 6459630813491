export const APP_PATHS = {
  TOURNAMENTS: {
    ROOT: '/',
  },
  ACCOUNT: {
    ROOT: '/account',
    FORGOT_PASSWORD: '/account/forgot-password',
    LOGIN: '/account/login',
    REGISTER: '/account/register',
    RESET_PASSWORD: '/account/reset-password',
    SECURITY_UPDATE: '/account/security-update',
  },
  PROFILE: {
    ROOT: '/profile',
    CHANGE_PASSWORD: '/profile/change-password',
  },
  PAYMENTS: {
    ROOT: '/payments',
    FAILURE: '/payments/failure',
    SUCCESS: '/payments/success',
  },
  CHECKOUT: {
    ROOT: '/checkout',
    SEASON: '/checkout/season',
    TOURNAMENT: '/checkout/tournament',
  },
  CONTACT: {
    ROOT: '/contact',
  },
  PRACTICE: {
    ROOT: '/practice',
  },
  SPONSORS: {
    ROOT: '/sponsors',
  },
};

export const EXTERNAL_PATHS = {
  WST_TV: 'https://wst.tv/',
};
