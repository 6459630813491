import EnvironmentInformation from '@components/layout/EnvironmentInformation/EnvironmentInformation';
import { PRIVACY_LINK, TERMS_LINK } from '@constants/terms';
import { Box } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export default function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
        pt: 1,
        pb: 1,
        pl: { xs: 1, lg: 0 },
        pr: { xs: 1, lg: 0 },
        color: theme.palette.text.secondary,
      }}
    >
      <Typography component="span" variant="body2" sx={{ pr: 1 }}>
        &copy; {new Date().getFullYear()} World Snooker Ltd. All rights reserved.
      </Typography>
      <MuiLink
        href={PRIVACY_LINK}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
        variant="body2"
      >
        Privacy Policy
      </MuiLink>
      <Typography component="span" variant="body2" sx={{ pl: 1, pr: 1 }}>
        |
      </Typography>
      <MuiLink
        href={TERMS_LINK}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
        variant="body2"
      >
        Terms &amp; Conditions
      </MuiLink>
      <EnvironmentInformation />
    </Box>
  );
}
