export interface ApiError {
  status: number;
  statusText: string;
  title: string;
  detail?: string | ApiValidationError[];
  code?: string;
}

export interface ApiValidationError {
  propertyName: string;
  errorMessage: string;
}

export function isApiError(value: any): value is ApiError {
  return (
    value !== undefined &&
    value != null &&
    (value as ApiError).title !== undefined &&
    (value as ApiError).status !== undefined &&
    (value as ApiError).statusText !== undefined
  );
}

export function isApiValidationError(value: any): value is ApiValidationError {
  return (
    value !== undefined &&
    value != null &&
    (value as ApiValidationError).propertyName !== undefined &&
    (value as ApiValidationError).errorMessage !== undefined
  );
}
