import { ApiError, ApiValidationError } from '@services/apiError';
import { ApiMethod } from '@services/api';
import getConfig from '@utils/runtimeConfig';
import { isString } from '@utils/typeGuards';
import { getAccessToken, signOut } from './authorization';

const request = async (
  path: string,
  method: string = ApiMethod.GET,
  data: any = null,
  token: string | undefined = undefined
): Promise<any> => {
  const { apiBaseUri } = getConfig();

  if (!token) {
    token = await getAccessToken();
  }

  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${apiBaseUri}${path}`, {
    headers,
    method,
    body: data ? JSON.stringify(data) : null,
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await parseErrorResponse(response);

        if (response.status === 401) {
          signOut();
        }

        return Promise.reject(error);
      }

      return response;
    })
    .then((response) => {
      const contentType = response.headers.get('content-type')!;

      if (contentType === null) {
        return Promise.resolve(null);
      } else if (contentType.startsWith('application/json')) {
        return response.json();
      } else if (contentType.startsWith('text/plain')) {
        return response.text();
      }

      return response;
    })
    .catch((error: Error) => {
      return Promise.reject(error);
    });
};

const parseErrorResponse = async (response: Response): Promise<ApiError> => {
  try {
    const apiError: ApiError = {
      status: response.status,
      statusText: response.statusText,
      title: 'The application has encountered an unknown error',
    };

    if (response.status === 401) {
      apiError.title = 'Not authorized';
      apiError.detail = 'You are not authorized to access this resource';
    } else {
      const responseBody = await response.text();
      const responseData = JSON.parse(responseBody);

      if (responseData.title) {
        apiError.title = responseData.title;
      }

      if (isString(responseData.code)) {
        apiError.code = responseData.code;
      }

      if (responseData.detail) {
        if (isString(responseData.detail)) {
          apiError.detail = responseData.detail;
        } else if (Array.isArray(responseData.detail)) {
          apiError.detail = responseData.detail as ApiValidationError[];
        }
      } else if (responseData.errors && Array.isArray(responseData.errors)) {
        apiError.detail = responseData.errors as ApiValidationError[];
      }
    }

    return apiError;
  } catch (err) {
    return {
      title: 'The application has encountered an unknown error',
      status: response?.status,
      statusText: response?.statusText,
    } as ApiError;
  }
};

export { request };
