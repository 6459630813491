export const API_VERSION = 'v1';

export const API_PATH = {
  accounts: `/${API_VERSION}/accounts`,
  countries: `/${API_VERSION}/countries`,
  messages: `/${API_VERSION}/messages`,
  applicants: `/${API_VERSION}/applicants`,
  tournamentsPlayer: `/${API_VERSION}/tournaments/player`,
  tournaments: `/${API_VERSION}/tournaments`,
  practiceDays: `/${API_VERSION}/practice-table-days`,
  practiceBlocks: `/${API_VERSION}/practice-table-blocks`,
  practiceTableBookings: `/${API_VERSION}/practice-table-bookings`,
  checkout: `/${API_VERSION}/checkout`,
  sponsors: `/${API_VERSION}/users/sponsors`,
  seasons: `/${API_VERSION}/seasons`,
  transactionStatus: `/${API_VERSION}/billings/transaction-status`,
};
