import { isApiError } from '@services/apiError';
import { clearAuthCookies, getTokenCookies, publishCookieChange, removeRefreshToken, setTokenCookies } from '@utils/cookies';
import { accountsApi } from './api/accounts';

const FIVE_MINUTES = 60000 * 5;

interface Credentials {
  username: string;
  password: string;
}

const refreshAccessToken = async () => {
  const { accessToken, refreshToken } = getTokenCookies();

  if (!refreshToken) {
    return accessToken;
  }

  removeRefreshToken();

  try {
    const refreshedTokens = await accountsApi.refreshToken({ accessToken, refreshToken });
    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = refreshedTokens.data;

    setTokenCookies(newAccessToken, newRefreshToken);
    publishCookieChange({ accessToken: newAccessToken, refreshToken: newRefreshToken });

    return newAccessToken;
  } catch (error: any) {
    if (error.status === 409) {
      signOut();
    }
    return accessToken;
  }
};

export const signIn = async ({ username, password }: Credentials) => {
  if (!username || !password) return;

  try {
    const user = await accountsApi.login(username, password);

    if (!user) return;

    return { ...user.data, username } as any;
  } catch (error: any) {
    let errorMessage = 'Unknown error occurred';
    if (isApiError(error)) {
      if (error.status === 422 && error.title === 'Validation error occurred') {
        errorMessage = 'Incorrect username and/or password';
      } else {
        errorMessage = error.title;
      }
    }
    return {
      error: errorMessage,
    };
  }
};

export const signOut = () => {
  clearAuthCookies();
};

export const getAccessToken = async () => {
  const { accessToken } = getTokenCookies();

  if (!accessToken) return;

  const parsedToken = JSON.parse(atob(accessToken.split('.')[1]));
  const exp = new Date(0);
  exp.setUTCSeconds(parsedToken.exp);

  if (Date.now() > exp.getTime() - FIVE_MINUTES) {
    const token = await refreshAccessToken();
    return token;
  }

  return accessToken;
};
